import { Box, SxProps } from '@mui/material'
import { RowInspirationGridFragment } from '../RowInspirationGrid.gql'
import { InspirationGridItem } from '../components/InspirationGridItem'
import { RowInspirationGridContainer } from '../components/RowInspirationGridContainer'
import { RowInspirationGridTitle } from '../components/RowInspirationGridTitle'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function ThreeInARow(props: RowInspirationGridFragment, isMirrored: boolean) {
  const {
    title,
    item1Asset,
    item1Link,
    item1Text,
    item2Asset,
    item2Link,
    item2Text,
    item3Asset,
    item3Link,
    item3Text,
  } = props

  const boxStyle: SxProps = {
    height: '100%',
    width: '100%',
    aspectRatio: '1/1.5',
  }

  return (
    <RowInspirationGridContainer>
      <RowInspirationGridTitle title={title} />
      <Box
        sx={(theme) => ({
          display: 'grid',
          gap: theme.spacings.sm,
          gridTemplateAreas: `
            "item1 item2 item3"
          `,
          gridTemplateColumns: 'repeat(3, 1fr)',
          gridAutoRows: '1fr',
        })}
      >
        <Box
          sx={{
            gridArea: 'item1',
            ...boxStyle,
          }}
        >
          <InspirationGridItem asset={item1Asset} link={item1Link} text={item1Text} />
        </Box>
        <Box
          sx={{
            gridArea: 'item2',
            ...boxStyle,
          }}
        >
          <InspirationGridItem asset={item2Asset} link={item2Link} text={item2Text} />
        </Box>
        <Box
          sx={{
            gridArea: 'item3',
            position: 'relative',
            ...boxStyle,
          }}
        >
          <InspirationGridItem asset={item3Asset} link={item3Link} text={item3Text} />
        </Box>
      </Box>
    </RowInspirationGridContainer>
  )
}
