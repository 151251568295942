import { Button, Container } from '@mui/material'
import { RowButtonFragment } from './RowButton.gql'

export function RowButton(props: RowButtonFragment) {
  const { caption, identity, link, size, color } = props
  const colorStyle = color === 'pink' ? 'primary' : 'secondary'

  return (
    <Container
      sx={(theme) => ({ display: 'grid', placeContent: 'center', mb: theme.spacings.lg })}
      key={identity}
    >
      <Button href={link} size={size} variant='contained' color={colorStyle}>
        {caption}
      </Button>
    </Container>
  )
}
