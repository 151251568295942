import { AssetFragment } from '@graphcommerce/graphcms-ui'
import { Image, ImageProps } from '@graphcommerce/image'
import { NextLink } from '@graphcommerce/next-ui'
import { Box, Chip, Typography } from '@mui/material'
import { setTitleClean } from '../../utils'

type InspirationGridItemClassicProps = {
  url: string
  title?: string | null
  inpirationGridTitle?: string | null
  asset?: AssetFragment | null
} & Pick<ImageProps, 'sizes'>

export function InspirationGridItemClassic(props: InspirationGridItemClassicProps) {
  const { url, asset, title, inpirationGridTitle, sizes } = props

  if (!url || !asset) return null

  const itemTagUrl = url.split('/')[0]
  const itemTag =
    itemTagUrl === 'diy'
      ? itemTagUrl.toUpperCase()
      : itemTagUrl.replace(/^./, itemTagUrl[0].toUpperCase())

  return (
    <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
      <Box
        sx={{ position: 'absolute', width: '100%', height: '100%' }}
        href={url}
        component={NextLink}
      >
        <Image
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
          src={asset.url}
          width={asset.width || 200}
          height={asset.height || 200}
          quality={75}
          alt={asset.alt ?? ''}
          sizes={sizes}
        />
        <Box
          sx={{
            background: 'linear-gradient(0deg, rgba(0,0,0,0.15) 20%, rgba(0,0,0,0) 100%)',
            position: 'absolute',
            width: '100%',
            height: '100%',
          }}
        />
      </Box>
      <Box
        sx={(theme) => ({
          width: '100%',
          position: 'absolute',
          color: 'primary.contrastText',
          bottom: 0,
          left: 0,
          padding: theme.spacings.xs,
        })}
      >
        <Chip
          href={itemTagUrl}
          component={NextLink}
          label={itemTag}
          variant='filled'
          size='medium'
          color='default'
          sx={{ mb: 1 }}
        />
        <Typography
          component='p'
          variant='h5'
          sx={(theme) => ({
            color: 'primary.contrastText',
            [theme.breakpoints.up('xs')]: {
              lineHeight: '1.1 !important',
            },
            [theme.breakpoints.up('md')]: {
              lineHeight: '1.5 !important',
            },
          })}
          dangerouslySetInnerHTML={{
            __html: setTitleClean(inpirationGridTitle || title),
          }}
        />
      </Box>
    </Box>
  )
}
