import { Typography } from '@mui/material'
import { setTitle } from '../../utils'

export function RowInspirationGridTitle({ title }: { title: string }) {
  return (
    <Typography
      component='h2'
      variant='h1'
      align='center'
      sx={(theme) => ({
        marginBottom: theme.spacings.md,
        textTransform: 'uppercase',
      })}
      dangerouslySetInnerHTML={{
        __html: setTitle(title),
      }}
    />
  )
}
