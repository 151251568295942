import { Box, SxProps } from '@mui/material'
import { RowInspirationGridFragment } from '../RowInspirationGrid.gql'
import { InspirationGridItem } from '../components/InspirationGridItem'
import { RowInspirationGridContainer } from '../components/RowInspirationGridContainer'
import { RowInspirationGridTitle } from '../components/RowInspirationGridTitle'

export function TwoPlusThree(props: RowInspirationGridFragment, isMirrored: boolean) {
  const {
    title,
    item1Asset,
    item1Link,
    item1Text,
    item2Asset,
    item2Link,
    item2Text,
    item3Asset,
    item3Link,
    item3Text,
    item4Asset,
    item4Link,
    item4Text,
    item5Asset,
    item5Link,
    item5Text,
  } = props

  const boxStyle: SxProps = {
    height: '100%',
    width: '100%',
  }

  return (
    <RowInspirationGridContainer>
      <RowInspirationGridTitle title={title} />
      <Box
        sx={(theme) => ({
          display: 'grid',
          gap: theme.spacings.sm,
          gridTemplateAreas: isMirrored
            ? `
            "item3 item3 item3 item1 item1"
            "item3 item3 item3 item1 item1"
            "item4 item4 item4 item1 item1"
            "item4 item4 item4 item2 item2"
            "item5 item5 item5 item2 item2"
            "item5 item5 item5 item2 item2"
          `
            : `
            "item1 item1 item3 item3 item3"
            "item1 item1 item3 item3 item3"
            "item1 item1 item4 item4 item4"
            "item2 item2 item4 item4 item4"
            "item2 item2 item5 item5 item5"
            "item2 item2 item5 item5 item5"
          `,
          gridTemplateColumns: 'repeat(5, 1fr)',
          gridAutoRows: '1fr',
        })}
      >
        <Box
          sx={{
            gridArea: 'item1',
            aspectRatio: '1/1',
            ...boxStyle,
          }}
        >
          <InspirationGridItem asset={item1Asset} link={item1Link} text={item1Text} />
        </Box>
        <Box
          sx={{
            gridArea: 'item2',
            aspectRatio: '1/1',
            ...boxStyle,
          }}
        >
          <InspirationGridItem asset={item2Asset} link={item2Link} text={item2Text} />
        </Box>
        <Box
          sx={{
            gridArea: 'item3',
            ...boxStyle,
          }}
        >
          <InspirationGridItem asset={item3Asset} link={item3Link} text={item3Text} />
        </Box>
        <Box
          sx={{
            gridArea: 'item4',
            ...boxStyle,
          }}
        >
          <InspirationGridItem asset={item4Asset} link={item4Link} text={item4Text} />
        </Box>
        <Box
          sx={{
            gridArea: 'item5',
            ...boxStyle,
          }}
        >
          <InspirationGridItem asset={item5Asset} link={item5Link} text={item5Text} />
        </Box>
      </Box>
    </RowInspirationGridContainer>
  )
}
