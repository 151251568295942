import { AssetFragment } from '@graphcommerce/graphcms-ui'
import { Image } from '@graphcommerce/image'
import { NextLink } from '@graphcommerce/next-ui'
import { Box, Typography } from '@mui/material'
import { setTitleClean } from '../../utils'
import { RowInspirationGridFragment } from '../RowInspirationGrid.gql'

type InspirationGridItemProps = {
  link?: RowInspirationGridFragment['item1Link']
  text?: RowInspirationGridFragment['item1Text']
  asset?: AssetFragment | null
}

export function InspirationGridItem(props: InspirationGridItemProps) {
  const { link, text, asset } = props

  return (
    <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
      <Box
        sx={{ position: 'absolute', width: '100%', height: '100%' }}
        href={link || undefined}
        component={link ? NextLink : 'div'}
      >
        {asset && (
          <Image
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
            src={asset.url}
            width={asset.width || 200}
            height={asset.height || 200}
            quality={75}
            alt={asset.alt ?? ''}
          />
        )}
        <Box
          sx={{
            background: 'linear-gradient(0deg, rgba(0,0,0,0.15) 20%, rgba(0,0,0,0) 100%)',
            position: 'absolute',
            width: '100%',
            height: '100%',
          }}
        />
      </Box>
      {text && (
        <Box
          sx={(theme) => ({
            width: '100%',
            position: 'absolute',
            color: 'primary.contrastText',
            bottom: 0,
            left: 0,
            padding: theme.spacings.xs,
          })}
        >
          <Typography
            component='p'
            variant='h5'
            sx={(theme) => ({
              color: 'primary.contrastText',
              [theme.breakpoints.up('xs')]: {
                lineHeight: '1.1 !important',
              },
              [theme.breakpoints.up('md')]: {
                lineHeight: '1.5 !important',
              },
            })}
            dangerouslySetInnerHTML={{
              __html: setTitleClean(text),
            }}
          />
        </Box>
      )}
    </Box>
  )
}
