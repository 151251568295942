import { RowInspirationGridFragment } from './RowInspirationGrid.gql'
import { InspirationGridClassic } from './components/InspirationGridClassic'
import { ThreeInARow } from './variants/ThreeInARow'
import { TwoPlusThree } from './variants/TwoPlusThree'
import { TwoPlusTwo } from './variants/TwoPlusTwo'

export function RowInspirationGrid(props: RowInspirationGridFragment) {
  const { inspirationGridVariant } = props
  const variants = {
    twoPlusTwo: TwoPlusTwo,
    twoPlusTwoMirrored: TwoPlusTwo,
    threeInARow: ThreeInARow,
    twoPlusThree: TwoPlusThree,
    twoPlusThreeMirrored: TwoPlusThree,
  }
  const isMirrored = inspirationGridVariant?.toLowerCase().includes('mirrored')

  if (!inspirationGridVariant) return <InspirationGridClassic {...props} />

  return <>{variants[inspirationGridVariant](props, Boolean(isMirrored))}</>
}
