import { Box, useTheme } from '@mui/material'
import { RowInspirationGridFragment } from '../RowInspirationGrid.gql'
import { InspirationGridItemClassic } from './InspirationGridItemClassic'
import { RowInspirationGridContainer } from './RowInspirationGridContainer'
import { RowInspirationGridTitle } from './RowInspirationGridTitle'

// The "Classic" version is the default (old) components that uses 8 pages as its input. Always renders 8 griditems with links to pages in hygraph, also gets its assets from those pages.
export function InspirationGridClassic(props: RowInspirationGridFragment) {
  const {
    title,
    rowInspirationGridItem1,
    rowInspirationGridItem2,
    rowInspirationGridItem3,
    rowInspirationGridItem4,
    rowInspirationGridItem5,
    rowInspirationGridItem6,
    rowInspirationGridItem7,
    rowInspirationGridItem8,
  } = props
  const t = useTheme()
  return (
    <RowInspirationGridContainer>
      <RowInspirationGridTitle title={title} />
      <Box
        sx={(theme) => ({
          display: 'grid',
          gap: theme.spacings.sm,
          aspectRatio: '2 / 6',
          gridTemplateAreas: `
          "item1 item1"
          "item1 item1"
          "item2 item3"
          "item4 item4"
          "item5 item6"
          "item7 item8"
        `,
          gridTemplateColumns: `repeat(2, 1fr)`,
          gridTemplateRows: 'repeat(6, 1fr)',

          [theme.breakpoints.up('sm')]: {
            aspectRatio: '3 / 3',
            gridTemplateAreas: `
            "item1 item2 item3"
            "item4 item4 item5"
            "item6 item7 item8"
          `,
            gridTemplateColumns: `repeat(3, 1fr)`,
            gridTemplateRows: 'repeat(3, 1fr)',
          },
          [theme.breakpoints.up('md')]: {
            aspectRatio: '5 / 3',
            gridTemplateAreas: `
            "item1 item2 item3 item3 item4"
            "item5 item5 item3 item3 item6"
            "item5 item5 item7 item8 item8"
          `,
            gridTemplateColumns: `repeat(5, 1fr)`,
            gridTemplateRows: 'repeat(3, 1fr)',
          },
        })}
      >
        <Box sx={{ gridArea: 'item1' }}>
          {rowInspirationGridItem1 && (
            <InspirationGridItemClassic
              {...rowInspirationGridItem1}
              sizes={{
                0: '100vw',
                [t.breakpoints.values.sm]: '33vw',
                [t.breakpoints.values.md]: '20vw',
                [t.breakpoints.values.xl]: '266px',
              }}
            />
          )}
        </Box>
        <Box sx={{ gridArea: 'item2' }}>
          {rowInspirationGridItem2 && (
            <InspirationGridItemClassic
              {...rowInspirationGridItem2}
              sizes={{
                0: '50vw',
                [t.breakpoints.values.sm]: '33vw',
                [t.breakpoints.values.md]: '20vw',
                [t.breakpoints.values.xl]: '266px',
              }}
            />
          )}
        </Box>
        <Box sx={{ gridArea: 'item3' }}>
          {rowInspirationGridItem3 && (
            <InspirationGridItemClassic
              {...rowInspirationGridItem3}
              sizes={{
                0: '50vw',
                [t.breakpoints.values.sm]: '33vw',
                [t.breakpoints.values.md]: '40vw',
                [t.breakpoints.values.xl]: '569px',
              }}
            />
          )}
        </Box>
        <Box sx={{ gridArea: 'item4' }}>
          {rowInspirationGridItem4 && (
            <InspirationGridItemClassic
              {...rowInspirationGridItem4}
              sizes={{
                0: '100vw',
                [t.breakpoints.values.sm]: '66vw',
                [t.breakpoints.values.md]: '20vw',
                [t.breakpoints.values.xl]: '266px',
              }}
            />
          )}
        </Box>
        <Box sx={{ gridArea: 'item5' }}>
          {rowInspirationGridItem5 && (
            <InspirationGridItemClassic
              {...rowInspirationGridItem5}
              sizes={{
                0: '50vw',
                [t.breakpoints.values.sm]: '33vw',
                [t.breakpoints.values.md]: '40vw',
                [t.breakpoints.values.xl]: '569px',
              }}
            />
          )}
        </Box>
        <Box sx={{ gridArea: 'item6' }}>
          {rowInspirationGridItem6 && (
            <InspirationGridItemClassic
              {...rowInspirationGridItem6}
              sizes={{
                0: '50vw',
                [t.breakpoints.values.sm]: '33vw',
                [t.breakpoints.values.md]: '20vw',
                [t.breakpoints.values.xl]: '266px',
              }}
            />
          )}
        </Box>
        <Box sx={{ gridArea: 'item7' }}>
          {rowInspirationGridItem7 && (
            <InspirationGridItemClassic
              {...rowInspirationGridItem7}
              sizes={{
                0: '50vw',
                [t.breakpoints.values.sm]: '33vw',
                [t.breakpoints.values.md]: '20vw',
                [t.breakpoints.values.xl]: '266px',
              }}
            />
          )}
        </Box>
        <Box sx={{ gridArea: 'item8' }}>
          {rowInspirationGridItem8 && (
            <InspirationGridItemClassic
              {...rowInspirationGridItem8}
              sizes={{
                0: '50vw',
                [t.breakpoints.values.sm]: '33vw',
                [t.breakpoints.values.md]: '40vw',
                [t.breakpoints.values.xl]: '569px',
              }}
            />
          )}
        </Box>
      </Box>
    </RowInspirationGridContainer>
  )
}
